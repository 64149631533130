import React, { Component } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import 'src/scss/style.scss';

import aws_config from 'src/aws-exports';
import Amplify, { API } from 'aws-amplify';
import { Auth } from '@aws-amplify/auth';
import { Authentication, getUserInfo, setUserInfo } from 'src/services/auth';
import Konami from 'react-konami-code';

API.configure(aws_config);
Amplify.configure(aws_config);
Auth.configure(aws_config);

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Containers
const DefaultLayout = React.lazy(() => import('src/layout/DefaultLayout'));

// Pages
const Login = React.lazy(() => import('src/views/pages/Login'));
const Page404 = React.lazy(() => import('src/views/pages/Page404'));
const Page500 = React.lazy(() => import('src/views/pages/Page500'));
const ChatBot = React.lazy(() => import('src/views/pages/ChatBot'));

class App extends Component {
  constructor(props) {
    super(props);
    // bind functions to 'this' so they can be called within react component calls
    this.componentDidMount = this.componentDidMount.bind(this);
    // analyze queryString to get 'code' and 'session'
    this.state = { userInfoLoaded: getUserInfo() !== null };
  }

  async componentDidMount() {
    // init user info
    await setUserInfo(); // set User Info on app mount so if auth has already happened, these values are ready
    this.setState({ userInfoLoaded: true });

    // if code and session, navigate to /#/stonks
    const queryString = window.location?.search; // ?code=<CODE>&session=<SESSION>
    const urlParams = new URLSearchParams(queryString);
    const code = urlParams.get('code');
    const session = urlParams.get('session');
    if (code && session && queryString) {
      const callback = window.location.origin;
      const redirect = callback + queryString;
      console.log('code and session present, navigating to stonks', {
        code,
        session,
        queryString,
        callback,
        redirect,
      });
      // post to stonks API
      const postStonksResp = await API.post('glorypantscomrest', '/stonks', {
        body: { code, session, queryString, callback, redirect },
      });
      // redirect to stonks page
      if (postStonksResp?.expiresAt) {
        window.location.href = `/#/stonks?expiresAt=${postStonksResp?.expiresAt}`;
      } else {
        window.location.href = `/#/stonks`;
      }
    }
  }

  render() {
    if (!this.state.userInfoLoaded) {
      return null;
    }
    return (
      <HashRouter>
        {/* re-mount after konami because top level code will set new developer level */}
        <Konami action={this.componentDidMount} timeout={1} />
        <Authentication />
        <React.Suspense fallback={loading}>
          <Switch>
            <Route exact path="/login" name="Login Page" render={(props) => <Login {...props} />} />
            <Route exact path="/404" name="Page 404" render={(props) => <Page404 {...props} />} />
            <Route exact path="/500" name="Page 500" render={(props) => <Page500 {...props} />} />
            <Route exact path="/bot" name="ChatBot" render={(props) => <ChatBot {...props} />} />
            <Route path="/" name="Home" render={(props) => <DefaultLayout {...props} />} />
          </Switch>
        </React.Suspense>
      </HashRouter>
    );
  }
}

export default App;
