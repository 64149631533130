export function fetchCsv(path) {
  return fetch(path).then(function (response) {
    let reader = response.body.getReader();
    let decoder = new TextDecoder('utf-8');

    return reader.read().then(function (result) {
      return decoder.decode(result.value);
    });
  });
}

export function objDiff(primaryObj, compareObj, nestDepth = 1, diffNumerics = false) {
  // returns object with prop values that differ between objects.
  // Default values are from primaryObj val, or compareObj is primary is null
  // nestDepth used to analyze nested objects. Value of 1 means objects not compared, value of 2 compares top level of objects
  // diffNumerics used to subtract compareObj numeric values from primaryObj
  const diffKeys = Object.keys(primaryObj).filter((key) => {
    // default true if strgified value mismatch
    const primaryVal = JSON.stringify(primaryObj[key]);
    const compareVal = JSON.stringify(compareObj[key]);
    let notEqual = primaryVal !== compareVal;
    if (typeof primaryObj[key] === 'object' && !Array.isArray(primaryObj[key])) {
      // if type object and not array, compare nestingly
      if (nestDepth > 1) {
        // if nest depth > 1, run objDiff on objects with nestDepth -= 1
        // include in filter if objDiff is not empty object
        const nestObjDiff = objDiff(primaryObj[key], compareObj[key], (nestDepth -= 1));
        notEqual = JSON.stringify(nestObjDiff) !== '{}';
      } else if (nestDepth <= 0) {
        // if nesting zero, false because we will ignore object values
        notEqual = false;
      }
    } else if (typeof primaryObj[key] !== typeof compareObj[key]) {
      // if type mismatch return true
      notEqual = true;
    }
    return notEqual;
  });

  let diff = {};
  for (const key of diffKeys) {
    if (
      diffNumerics &&
      typeof primaryObj[key] === 'number' &&
      compareObj.hasOwnProperty(key) &&
      typeof compareObj[key] === 'number'
    ) {
      // if diffNumerics and both are numerics, subtract compare from primary
      diff[key] = primaryObj[key] - compareObj[key];
    } else {
      diff[key] = primaryObj[key] ?? compareObj[key];
    }
  }
  return diff;
}

export function objContains(obj, subObj) {
  // Check if subObject exists within primary object
  if (!obj || !subObj) return null;

  const objProps = Object.getOwnPropertyNames(obj);
  const subProps = Object.getOwnPropertyNames(subObj);

  if (subProps.length > objProps.length) {
    return false;
  }
  for (const subProp of subProps) {
    if (!obj.hasOwnProperty(subProp)) {
      return false;
    }
    if (typeof obj[subProp] === 'object' && typeof subObj[subProp] === 'object') {
      return objContains(obj[subProp], subObj[subProp]);
    } else {
      if (obj[subProp] !== subObj[subProp]) {
        return false;
      }
    }
  }
  return true;
}

export function navHref(to) {
  if (to !== '/' && !to.includes('/#/') && window.location.href.includes('/#/')) {
    to = '/#' + to;
  }
  window.location.href = to;
}
