import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { Auth } from '@aws-amplify/auth';
import Konami from 'react-konami-code';
import { CModal, CModalBody, CModalHeader, CModalTitle } from '@coreui/react';
import { navHref } from 'src/lib/utils';

export async function signIn(
  username, //
  password,
  resetCode = null,
  newpassword = null,
  newPassRequiredAttributes = {},
) {
  // run Amplify authentication, return status from sign-in
  // if new password required, return status so that login page shows the new password prompt
  // if resetcode passed, use that for login with password
  setUserInfo(null);
  let statusReturn;
  if (!resetCode) {
    // if not reset code, use just username and password
    let user;
    try {
      user = await Auth.signIn(username, password);
    } catch (e) {
      console.log('signIn err', e);
      return { status: 'signIn err', error: e.message };
    }
    if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
      // if new password required, use newpassword input
      const { requiredAttributes } = user.challengeParam; // the array of required attributes, e.g ['email', 'phone_number']
      if (newpassword) {
        try {
          const data = await signInNewPassword(user, newpassword, newPassRequiredAttributes);
          console.log('signInNewPassword', data);
        } catch (e) {
          console.log('signInNewPassword err', e);
          return { status: 'signInNewPassword err', error: e.message };
        }
        if (statusReturn?.status) {
          return statusReturn;
        }
      } else {
        // return status regarding needing a new password
        return { status: 'new_password_required', requiredAttributes };
      }
    }
  } else {
    try {
      const data = await Auth.forgotPasswordSubmit(username, resetCode, password);
      console.log('forgotPasswordSubmit', data);
    } catch (e) {
      console.log('forgotPasswordSubmit err', e);
      return { status: 'forgotPasswordSubmit err', error: e.message };
    }
    try {
      const data = await Auth.signIn(username, password);
      console.log('signIn', data);
    } catch (e) {
      console.log('signIn after forgotPassword err', e);
      return { status: 'signIn after forgotPassword err', error: e.message };
    }
  }
  // after sign in get latest userinfo and check authentication
  await setUserInfo();
  if (userIsAuthenticated()) {
    // if authenticated, get subdomain redirect so we can handle redirect from login. to location.
    // this does nothing if the user is already on the correct subdomain
    const domain = window.location.hostname;
    if (domain.includes('.')) {
      const subdomain = domain.split('.')[0];
      const subdomainRedirect = getSubdomainRedirect();
      const currentUrl = window.location.toString();
      if (subdomainRedirect && !currentUrl.includes(subdomainRedirect)) {
        // if redirect, perform redirect to href and perform sign-in again at new domain
        // this fails because sign in occurs before the href. Would need to work on cross-domain sign in somehow
        const newUrl = currentUrl.replace(subdomain, subdomainRedirect);
        setSubdomainRedirect(null);
        await Auth.signOut();
        window.location.href = newUrl;
        // await signIn(username, newpassword ? newpassword : password);
      }
    }
  } else {
    // failed auth, sign out and return error
    await Auth.signOut();
    await setUserInfo(null);
    return { status: 'not_authorized', error: 'User not authorized for this site.' };
  }
  return { status: 'success' };
}

async function signInNewPassword(userObject, password, requiredAttributes = {}) {
  await Auth.completeNewPassword(
    userObject, // the Cognito User Object
    password, // the new password
    requiredAttributes,
  )
    .then((user) => {
      // at this time the user is logged in if no MFA required
      console.log('completeNewPassword user', user);
    })
    .catch((e) => {
      console.log('completeNewPassword err', e);
    });
}

export async function signOut(redirect = true) {
  try {
    await Auth.signOut();
    await setUserInfo(null);
    redirect && navHref('/login');
  } catch (error) {
    console.log('error signing out: ', error);
  }
}

export async function forgotPassword(username) {
  let errorOut;
  await Auth.forgotPassword(username)
    .then((data) => console.log('forgotPassword data', data))
    .catch((err) => {
      console.log('forgotPassword err', err);
      errorOut = err;
    });
  return errorOut;
}

export function getUserGroup(currentAuthenticatedUser) {
  // get user and user groups from AWS Auth
  // based on user group, return userGroup
  const groups = currentAuthenticatedUser.signInUserSession.accessToken.payload['cognito:groups'];
  if (groups?.includes('devs')) {
    return 'devs';
  } else if (groups?.includes('jamber')) {
    return 'jamber';
  } else if (groups?.includes('fbadmins')) {
    return 'fbadmins';
  } else if (groups?.includes('fbusers')) {
    return 'fbusers';
  } else {
    return 'public';
  }
}

const LEVEL_DEVS = { group: 'devs', level: 0 };
const LEVEL_JAMBER = { group: 'jamber', level: 10 };
const LEVEL_FBADMINS = { group: 'fbadmins', level: 20 };
const LEVEL_FBUSERS = { group: 'fbusers', level: 30 };
const LEVELS = [LEVEL_DEVS, LEVEL_JAMBER, LEVEL_FBADMINS, LEVEL_FBUSERS];
export function getUserLevel(userGroup) {
  // get user level from group name
  let userLevel;
  switch (userGroup) {
    case LEVEL_DEVS.group:
      userLevel = LEVEL_DEVS.level;
      break;
    case LEVEL_JAMBER.group:
      userLevel = LEVEL_JAMBER.level;
      break;
    case LEVEL_FBADMINS.group:
      userLevel = LEVEL_FBADMINS.level;
      break;
    case LEVEL_FBUSERS.group:
      userLevel = LEVEL_FBUSERS.level;
      break;
    default:
      userLevel = 100;
  }
  return userLevel;
}

export function getUserInfo() {
  // get user info from session storage as object
  let userInfo_str = window.localStorage.getItem('userInfo');
  let userInfo = null;
  if (userInfo_str !== '') {
    try {
      userInfo = JSON.parse(userInfo_str);
    } catch (error) {
      console.error('Failed parsing local storage, clearing local storage', error);
      window.localStorage.clear();
      window.location.reload();
    }
  }
  return userInfo;
}

export async function setUserInfo(userInfo = undefined) {
  const currentUserInfo = getUserInfo();
  if (userInfo === undefined && !currentUserInfo) {
    // if no userInfo input and not already set, get user info from Auth, set user info to session storage
    try {
      const user = await Auth.currentAuthenticatedUser();
      const name = user.username;
      const email = user.attributes.email;
      const group = getUserGroup(user);
      const level = getUserLevel(group);
      userInfo = {
        name,
        email,
        group,
        level,
      };
    } catch (error) {
      console.log('failed setUserInfo, setting to empty, error:', error);
      userInfo = null;
    }
  } else if (userInfo === undefined && currentUserInfo) {
    // return current user info if already set and user has not input their own
    return currentUserInfo;
  }
  console.log('setUserInfo', JSON.stringify(userInfo));
  window.localStorage.setItem('userInfo', userInfo && JSON.stringify(userInfo));
  return userInfo;
}

export function setSubdomainRedirect(subdomain) {
  window.localStorage.setItem('subdomainRedirect', subdomain);
}
export function getSubdomainRedirect() {
  return window.localStorage.getItem('subdomainRedirect');
}

export async function incrementUserLevel() {
  // get userInfo.level, get next index in LEVELS and set
  let userInfo = getUserInfo();
  if (userInfo) {
    console.log('Konami!! userInfo before set:', userInfo);
    const currentLevel = LEVELS.map((L) => L.level).indexOf(userInfo.level);
    const newLevelIndex = (currentLevel + 1) % LEVELS.length;
    userInfo.level = LEVELS[newLevelIndex].level;
    userInfo.group = LEVELS[newLevelIndex].group;
    userInfo = await setUserInfo(userInfo);
    return userInfo;
  } else {
    console.error('Failed getUserInfo', userInfo);
  }
}

export function userIsDev(userInfo = getUserInfo()) {
  return userInfo && userInfo.level <= LEVEL_DEVS.level;
}
export function userIsJamber(userInfo = getUserInfo()) {
  return userInfo && userInfo.level <= LEVEL_JAMBER.level;
}
export function userIsFBAdmin(userInfo = getUserInfo()) {
  return userInfo && userInfo.level <= LEVEL_FBADMINS.level;
}
export function userIsFBUser(userInfo = getUserInfo()) {
  return userInfo && userInfo.level <= LEVEL_FBUSERS.level;
}

export function userIsAuthenticated(userInfo = getUserInfo()) {
  const authenticated = userInfo && userInfo.name;
  return authenticated;
}

export class Authentication extends React.Component {
  constructor(props) {
    super(props);
    // bind functions to 'this' so they can be called within react component calls
    this.toggleUserLevel = this.toggleUserLevel.bind(this);
    this.getModalAlert = this.getModalAlert.bind(this);
    this.closeModalAlert = this.closeModalAlert.bind(this);
    // set session variable for userGroup to public by default
    window.localStorage.setItem('userInfo', '');
    this.state = {
      modalAlert: {
        visible: false,
        title: '',
        body: '',
      },
    };
  }

  async componentDidMount() {
    await setUserInfo();
  }

  async toggleUserLevel() {
    const userInfo = await incrementUserLevel();
    const modalAlert = {
      visible: true,
      title: 'Toggled User Level',
      body: 'New level: ' + String(userInfo.group),
    };
    this.setState({ modalAlert });
    this.forceUpdate();
  }

  closeModalAlert() {
    const { modalAlert } = this.state;
    modalAlert.visible = false;
    this.setState({ modalAlert });
  }

  getModalAlert() {
    const { modalAlert } = this.state;
    return (
      <CModal visible={modalAlert.visible} onClose={this.closeModalAlert} size="sm">
        <CModalHeader closeButton>
          <CModalTitle>{modalAlert.title}</CModalTitle>
        </CModalHeader>
        <CModalBody>{modalAlert.body}</CModalBody>
      </CModal>
    );
  }

  render() {
    return (
      <>
        {this.getModalAlert()}
        <Konami action={this.toggleUserLevel} timeout={1} />
      </>
    );
  }
}

// return route if authenticated
// else, return login route
// if not auth, set from to home because that route doesn't exist for this user
export function PrivateRoute({ ...props }) {
  return userIsAuthenticated() ? (
    <Route {...props} />
  ) : (
    <Redirect to={{ pathname: '/login', state: { referrer: props?.location } }} />
  );
}

export function PrivateDevRoute({ ...props }) {
  return userIsDev() ? (
    <Route {...props} />
  ) : (
    <Redirect to={{ pathname: '/login', state: { referrer: props?.location } }} />
  );
}

export function PrivateJamberRoute({ ...props }) {
  return userIsJamber() ? (
    <Route {...props} />
  ) : (
    <Redirect to={{ pathname: '/login', state: { referrer: props?.location } }} />
  );
}

export function PrivateFBAdminsRoute({ ...props }) {
  return userIsFBAdmin() ? (
    <Route {...props} />
  ) : (
    <Redirect to={{ pathname: '/login', state: { referrer: props?.location } }} />
  );
}

export function PrivateFBUsersRoute({ ...props }) {
  return userIsFBUser() ? (
    <Route {...props} />
  ) : (
    <Redirect to={{ pathname: '/login', state: { referrer: props?.location } }} />
  );
}
